<template>
  <div class="">
    <div
      class="card-table"
      :class="mode ? 'card__tableday' : 'card__tablenight'"
    >
      <div class="card-table-body ifon-add-title-block">
        <el-row :gutter="20">
          <el-col :span="12">
            <div class="caz-blocks-sarcho-title">
              <div
                class="content-title d-flex align-center mr-2"
                :class="mode ? 'content__titleday' : 'content__titlenight'"
              >
                {{ $t("message.services") }}
              </div>
              <div class="block-sarche">
                <div class="header__search">
                  <crm-input
                    :size="'small'"
                    :className="'w100'"
                    :class="mode ? 'input__day' : 'input__night'"
                    v-model="filterForm.search"
                    :icon="'el-icon-search'"
                  ></crm-input>
                </div>
              </div>
            </div>
          </el-col>

          <el-col :span="12" class="flex-style text-right">
            <crm-create-and-column-settings
              :permission="$options.name"
              :class="mode ? 'button__settingsday' : 'button__settingsnight'"
              @c-create="drawerCreate = true"
              :columns="columns"
              @c-change="updateColumn"
            >
            </crm-create-and-column-settings>
          </el-col>
        </el-row>
      </div>
      <!-- end ifon-add-title-block -->

      <div class="card-table-header table-crm-smart custom__scroll">
        <table
          class="table-my-code table-bordered"
          :class="mode ? 'table__myday' : 'table__mynight'"
          v-loading="loadingData"
        >
          <thead>
            <tr>
              <th class="w50p" v-if="columns.id.show">
                {{ columns.id.title }}
              </th>

				      <th v-if="columns.name.show">
                {{ columns.name.title }}
              </th>

              <th v-if="columns.calculation_type.show">
                {{ columns.calculation_type.title }}
              </th>

              <th v-if="columns.calculate_by.show">
                {{ columns.calculate_by.title }}
              </th>

				      <th v-if="columns.total_price.show">
                {{ columns.total_price.title }}
              </th>

				      <th v-if="columns.currency.show">
                {{ columns.currency.title }}
              </th>

				      <th v-if="columns.deal_types.show">
                {{ columns.deal_types.title }}
              </th>

              <th v-if="columns.service_category_id.show">
                {{ columns.service_category_id.title }}
              </th>

              <th v-if="columns.show_cabinet.show">
                {{ columns.show_cabinet.title }}
              </th>

              <th v-if="columns.auto_set.show">
                {{ columns.auto_set.title }}
              </th>

              <th v-if="columns.created_at.show">
                {{ columns.created_at.title }}
              </th>

              <th v-if="columns.updated_at.show">
                {{ columns.updated_at.title }}
              </th>

              <th v-if="columns.settings.show">
                {{ columns.settings.title }}
              </th>
            </tr>

            <tr class="filter_sorche">
              <th v-if="columns.id.show">
                <el-input
                  clearable
                  size="mini"
                  v-model="filterForm.id"
                  :class="mode ? 'filter__day' : 'filter__night'"
                  :placeholder="columns.id.title"
                  class="id_input"
                ></el-input>
              </th>

				  
				      <th v-if="columns.name.show">
                <crm-input
                  :placeholder="columns.name.title"
                  :class="mode ? 'filter__day' : 'filter__night'"
                  v-model="filterForm.name"
                ></crm-input>
              </th>
				  
				      <th v-if="columns.calculation_type.show">
                
                <select-calculation-types 
                  v-model="filterForm.calculation_type" 
                  :id="filterForm.calculation_type"
                  :class="mode ? 'filter__day' : 'filter__night'"
                ></select-calculation-types>
              </th>

              <th v-if="columns.calculate_by.show">
                
                <select-calculate-by-types 
                  v-model="filterForm.calculate_by" 
                  :id="filterForm.calculate_by"
                  :class="mode ? 'filter__day' : 'filter__night'"
                ></select-calculate-by-types>
              </th>

				      <th v-if="columns.total_price.show">
                <crm-input
                  :placeholder="columns.total_price.title"
                  :class="mode ? 'filter__day' : 'filter__night'"
                  v-model="filterForm.total_price"
						      disabled
                ></crm-input>
              </th>

              <th v-if="columns.currency.show">
                <select-currency 
                  v-model="filterForm.currency_id" 
                  :id="filterForm.currency_id"
                  :class="mode ? 'filter__day' : 'filter__night'"
                ></select-currency>
              </th>

              <th v-if="columns.deal_types.show">
                <crm-input
                  :placeholder="columns.deal_types.title"
                  :class="mode ? 'filter__day' : 'filter__night'"
                  v-model="filterForm.name"
                  disabled
                ></crm-input>
              </th>

              <th v-if="columns.service_category_id.show">
                <crm-input
                  :placeholder="columns.service_category_id.title"
                  :class="mode ? 'filter__day' : 'filter__night'"
                  v-model="filterForm.service_category_id"
                  disabled
                ></crm-input>
              </th>

              <th v-if="columns.show_cabinet.show">
                <crm-input
                  :placeholder="columns.show_cabinet.title"
                  :class="mode ? 'filter__day' : 'filter__night'"
                  v-model="filterForm.show_cabinet"
                  disabled
                ></crm-input>
              </th>

              <th v-if="columns.auto_set.show">
              </th>

              <th v-if="columns.created_at.show">
                <crm-date-picker
                  :placeholder="columns.created_at.title"
                  :class="mode ? 'filter__day' : 'filter__night'"
                  v-model="filterForm.created_at"
                ></crm-date-picker>
              </th>

              <th v-if="columns.updated_at.show">
                <crm-date-picker
                  :placeholder="columns.updated_at.title"
                  :class="mode ? 'filter__day' : 'filter__night'"
                  v-model="filterForm.updated_at"
                ></crm-date-picker>
              </th>

              <th class="settinW" v-if="columns.settings.show"></th>
            </tr>
          </thead>

          <transition-group name="flip-list" tag="tbody">
            <tr
              v-for="service in list"
              :key="service.id"
              class="cursor-pointer"
            >
              <td v-if="columns.id.show">
                {{ service.id }}
              </td>

              <td v-if="columns.name.show">
                {{ service.name }}
              </td>

				      <td v-if="columns.calculation_type.show">
                {{ getCalcType(service.calculation_type) }}
              </td>

              <td v-if="columns.calculate_by.show">
                {{ getCalcByType(service.calculate_by) }}
              </td>

				      <td v-if="columns.total_price.show">
                {{ service.service_sum | formatNumber() }}
              </td>

				      <td v-if="columns.currency.show">
                {{ service.currency ? service.currency.symbol : '' }}
              </td>

              <td v-if="columns.deal_types.show">
                <div v-for="(item, index) in service.items" :key="index">
                  <el-tag v-if="item.deal_type == 'commercial'">{{ $t('message.commertial_cargo') }} <br></el-tag>
                  <el-tag type="warning" v-else>{{ $t('message.parcel') }}</el-tag>
                </div>
              </td>

              <td v-if="columns.service_category_id.show">
                {{ service.service_category ? service.service_category.name : '' }}
              </td>

              <td v-if="columns.show_cabinet.show">
                {{ service.show_cabinet ? $t('message.show_cabinet') : '' }}
              </td>

              <td v-if="columns.auto_set.show">
                {{ service.auto_set ? $t('message.auto_set') : '' }}
              </td>

              <td v-if="columns.created_at.show">
                {{ service.created_at }}
              </td>

              <td v-if="columns.updated_at.show">
                {{ service.updated_at }}
              </td>

              <td v-if="columns.settings.show" class="settings-td">
                <el-dropdown @command="handleCommand">
                    <span class="el-dropdown-link more_icons">
                        <i class=" el-icon-arrow-down el-icon-more-outline"></i>
                    </span>
                    <el-dropdown-menu  slot="dropdown" size="mini" >
                        <el-dropdown-item v-if="permissions.some(per => per.slug == 'services.update')" :command="{ action: 'edit', model: service }" icon="el-icon-edit el-icon--left">       
                            {{ $t("message.update") }}
                        </el-dropdown-item>
                        <el-dropdown-item :command="{ action: 'price_list', model: service }" icon="el-icon-money el-icon--left">
                            {{ $t("message.price_list") }}
                        </el-dropdown-item>
                        <el-dropdown-item v-if="permissions.some(per => per.slug == 'services.delete')" :command="{ action: 'delete', model: service }" icon="el-icon-delete el-icon--left">
                            {{ $t("message.delete") }}
                        </el-dropdown-item>
                    </el-dropdown-menu>
                </el-dropdown>
              </td>
            </tr>
          </transition-group>
        </table>
        <div class="my___pagination">
          <crm-pagination
            @c-change="updatePagination"
            :class="mode ? 'pagination__day' : 'pagination__night'"
            :pagination="pagination"
          ></crm-pagination>
          <!-- <Pagination /> -->
        </div>
      </div>

      <div class="app-modal app-modal__full modal-color-bg">
        <el-drawer
          :with-header="false"
          :visible.sync="drawerCreate"
          ref="drawerCreate"
          size="80%"
          class="bg-se"
          @opened="drawerOpened('drawerCreateChild')"
          @closed="drawerClosed('drawerCreateChild')"
        >
          <div>
            <crm-create ref="drawerCreateChild" drawer="drawerCreate">
            </crm-create>
          </div>
        </el-drawer>

        <el-drawer
          :with-header="false"
          :visible.sync="drawerUpdate"
          size="80%"
          ref="drawerUpdate"
          class="bg-se"
          @opened="drawerOpened('drawerUpdateChild')"
          @closed="drawerClosed('drawerUpdateChild')"
        >
          <crm-update
            :selectedItem="selectedItem"
            ref="drawerUpdateChild"
            drawer="drawerUpdate"
          ></crm-update>
        </el-drawer>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import list from "@/utils/mixins/list";
import Pagination from "@/components/el-pagination";
import CrmCreate from "./components/crm-create";
import CrmUpdate from "./components/crm-update";
import { mapGetters, mapActions } from "vuex";
import SelectCurrency from '../../components/selects/select-currency';
import SelectCalculationTypes from '@/components/inventory/select-service-calculation-types';
import SelectCalculateByTypes from '@/components/inventory/select-service-calculate-by-types';

export default {
  name: "services",
  mixins: [list],
  components: {
    Pagination,
    CrmCreate,
    CrmUpdate,
	  SelectCurrency,
    SelectCalculateByTypes,
    SelectCalculationTypes,
  },

  data() {
    return {
      priceList: false,
    };
  },

  computed: {
    ...mapGetters({
      permissions: "auth/permissions",
      list: "services/list",
      columns: "services/columns",
      pagination: "services/pagination",
      filter: "services/filter",
      sort: "services/sort",
      calculationTypes: "services/calculationTypes",
      serviceCalculateTypes: "services/serviceCalculateTypes",
      mode: "MODE",
    }),
    actions: function () {
      return ["edit", "delete"];
    },
  },
  methods: {
    ...mapActions({
      updateList: "services/index",
      setPagination: "services/setPagination",
      updateSort: "services/updateSort",
      updateFilter: "services/updateFilter",
      updateColumn: "services/updateColumn",
      updatePagination: "services/updatePagination",
      show: "services/show",
      empty: "services/empty",
      delete: "services/destroy",
      refreshData: "services/refreshData",
    }),
    handleCommand(command) {
        this.selectedItem = command.model;
        if (command.action === "delete") {
            this.$confirm(
                this.$t('message.do_you_really_want_to_do_this'),
                this.$t("message.warning"), {
                    confirmButtonText: this.$t("message.yes"),
                    cancelButtonText: this.$t("message.no"),
                    type: "warning"
                }
                )
                .then(() => {
                    this.destroy(command.model);
                })
                .catch(() => {
                    this.$message({
                        type: "warning",
                        message: this.$t("message.operation_canceled")
                    });
                });
        } else if(command.action === "edit") {          
            this.edit(command.model);
        } else if(command.action === "price_list") {          
            this.priceList = true;
        }
    },
    getCalcType(calculation_type) {
      let calc = this.calculationTypes.find(el => el.id == calculation_type);
      return calc ? calc.name : calculation_type;
    },
    getCalcByType(calculate_by) {
      let calc = this.serviceCalculateTypes.find(el => el.id == calculate_by);
      return calc ? calc.name : calculate_by;
    },
    
    
  },
  beforeRouteLeave(to, from, next) {
    this.$store.commit("services/EMPTY_LIST");
    next();
  },
};
</script>
<style>
.link{
	text-decoration: none;
}
</style>

